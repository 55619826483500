.container {
    width: 70%;
    margin: 0 auto;
}

.teacherInfoBlock{
    margin: 1rem 0;
    width: 100%;
    max-width: 550px;
}

.teacherInfoTitle {
    text-align: center;
    color: #376fb9;
    font-weight: 500;
}

.teacherInfoItem {
    display: flex;
}

.teacherInfoItem > div:first-child {
    width: 123px;
    text-align: right;
    font-weight: 500;
    color: #376fb9;
}

.teacherInfoItem > div:last-child {
    margin-left: .5rem;
    width: calc(100% - 123px - .5rem);
    color: grey;
}

.teacherPhoto {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teacherPhoto > div {
    width: 80%;
    max-width: 260px;
    height: 100%;
    background-color: #f1f2f3;
    color: #376fb9;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
}

.teacherPhoto > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.formBlockTitle {
    text-align: center;
    color: #376fb9;
    margin-top: 2rem;
}

.formLabels {
    color: #376fb9;
}

.errorLabel {
    color: red;
}

.filesBlock {
    display: flex;
    margin-bottom: 2rem;
}

.filesBlock > div {
    flex-basis: 50%;
}

.requestFiles > div, .teacherInfoFiles > div {
    display: flex;
    flex-wrap: wrap;
}

.fileItem + .fileItem {
    margin-left: 1rem;
}

.fileItem > span {
    color: #376fb9;
    display: flex;
    align-items: end;
    height: 50px;
    margin-bottom: .5rem;
}

.expertFileLabel {
    font-size: .8rem;
    line-height: 1.1;
}

.criterionsWrapper > table td {
    word-break: break-word;
}

.criterionsWrapper > ul {
    list-style: none;
}

.criterionsWrapper > b {
    text-align: center;
    line-height: normal;
    margin: -.5rem 0 1.5rem;
    font-size: .9rem;
    color: #376fb9;
}

.criterionTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    color: #5c8cc7;
    transition: .2s;
}

.criterionTitle:hover > div {
    color: #376fb9;
}

.criterionTitle:hover h4 {
    color: #376fb9;
    text-decoration: underline;
    /* text-decoration: underline; */
}

.criterionTitle > div {
    font-size: 2rem;
    margin-right: 1rem;
}

.criterionTitle h4 {
    width: 100%;
    max-width: 700px;
    margin-bottom: 0;
}

.criterionTitle i {
    font-size: 34px;
    margin-left: 2rem;
}

.criterionModal > h4 {
    margin-bottom: 0;
}

.criterionDescription {
    font-size: .7rem;
    font-style: italic;
}

.quill > div:first-child {
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
}

.quill > div:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
}

.quill > div:last-child {
    height: 200px;
}

.error > div {
    border: 1px solid red !important;
}

.criterionButtons {
    margin-top: 2rem;
    display: flex;
}

.criterionButtons button + button {
    margin-left: 2rem;
}

.listBlock {
    margin-top: 1rem;
    border: 1px solid #dfdfdf;
    border-radius: .5rem;
    overflow: hidden;
}

.listTitle {
    padding: .5rem;
    text-align: center;
    color: white;
    font-weight: 500;
    background-color: #336fb9;
    cursor: pointer;
    border-radius: .5rem;
    letter-spacing: 1px;
    user-select: none;
}

.list {
    max-height: 300px;
    overflow: auto;
}

.listRow {
    display: flex;
    color: #376fb9;
    cursor: pointer;
}
.listRow:hover {
    background-color: #cfe2ff;
}

.listRow div:nth-child(1) {
    flex-basis: 30%;
}

.listRow div:nth-child(2) {
    flex-basis: 40%;
}

.listRow div:nth-child(3) {
    flex-basis: 27%;
}

.listRow div:nth-child(4) {
    flex-basis: 3%;
    color: red;
    font-size: 1.2rem;
}

.portfolioFiles {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleTd {
    text-align: center;
}

.yearsInput > input {
    width: 60px;
}

.validPoints {
    font-size: .9rem;
    font-style: italic;
}

.yearsDescription {
    text-align: center;
    width: 100%;
    font-size: .9rem;
    font-style: italic;
    color: #7f7f7f;
}

.moreInfoBlock > h5 {
    color: #376fb9;
}

.moreInfoBlock > * + * {
    margin-top: 1rem;
}

.moreInfoBlock > div > label {
    margin-bottom: 0;
}

.moreInfoBlock table {
    width: 80%;
  }

.moreInfoBlock thead tr {
    background-color: #4682b45e;
    color: #616060;
}

.storageButton {
    margin-top: 1rem;
    padding: 10px !important;
    border-radius: .5rem !important;
    text-align: center;
    color: white;
    font-weight: 500;
    width: 100%;
}

.criterionWithChild > td {
    background-color: #fff2dc6c;
    cursor: initial !important;
}

.noDataRow {
    text-align: center;
    font-style: italic;
    color: grey;
}


.filesModal {
    width: 400px;
}

.fileModalButton {
    width: 70px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ced4da;
    border-radius: .5rem;
    cursor: pointer;
}

.fileModalButton:hover {
    border-style: solid;
}

.buttonsBlock {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.simpleCertRow {
    display: flex;
    justify-content: center;
    margin: -.5rem 0 1rem;
}

.simpleCertRow > div {
    width: fit-content;
}
.filesModal {
  width: 400px;
}

.fileModalButton {
  width: 70px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ced4da;
  border-radius: .5rem;
  cursor: pointer;
}

.fileModalButton:hover {
  border-style: solid;
}

.buttonsBlock {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.fileItem + .fileItem {
  margin-left: 1rem;
}

.fileItem > span {
  color: #376fb9;
  display: flex;
  align-items: end;
  height: 50px;
  margin-bottom: .5rem;
}

.formLabels {
  color: #376fb9;
}
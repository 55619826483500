.popUpWrap {
  position: relative;
}

.popUp {
  width: 400px;
  border: 1px solid black;
  position: absolute;
  background-color: #f9f9f9;
  top: calc(100% + .5rem);
  left: 50%;
  opacity: 0;
  z-index: -1;
  transition: .5s;
  list-style: none;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: .2rem;
  padding-left: 0;
}

.popUp > li {
  padding: .5rem;
  user-select: none;
  letter-spacing: -1px;
}

.popUp > li:hover {
  background-color: rgb(127, 193, 255);
  color: #fff;
}

.popUpOpen {
  opacity: 1;
  z-index: 0;
}
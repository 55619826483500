.container {
    padding-top: 30px;
    width: 100%;
}

.authButtons {
    width: 100%;
    margin-top: calc((100vh - 222px) / 2 - 150px) ;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.authButtons button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    outline: none;
    border: none;
    background: linear-gradient(-45deg, #376fb9, #4c87d4);
	animation: gradient 5s ease infinite;
    color: white;
    font-size: 2rem;
    border-radius: .5rem;
    margin: 3rem;
    transition: .5s;
}

.authButtons button + button {

}

.authButtons button:hover {
    transform: translateY(-.5rem);
}

.formContainer {
    margin: 0 auto;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    margin-top: 55px;
    margin-bottom: 45px;
    text-transform: uppercase;
    color: #4c6fb6;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.iconContainer{
    width: 100%;
    text-align: center;
}

.userIcon {
    display: inline-block;
    height: 96px;
    width: 96px;
    background-image: url('../../assets/images/userImg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container form > input, .container form > select {
    display: inline-block;
    outline: none;
    border: 1px solid #c8c7c7;
    /* background-color: #fff!important; */
    width: 100%;
    margin: 10px 0;
    border-radius: 4px;
    padding: 8px;
}

.container form > div {
    width: 100%;
}
.wrapper_selects {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    flex-wrap: wrap;
    margin-top: .5rem;
}

.inputBlock {
    display: flex;
    flex-direction: column;
}

.inputBlock input {
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    appearance: none;
    padding-left: .5rem;
    outline: none;
}

.wrapperTop {
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.input_lessons {
    width: 30%;
    margin: .5rem auto;
}

.input_search {
    width: 90%;
    margin: .5rem 0 0 0;
}

.input_parallel {
    width: 20%;
    margin: .5rem auto;
}

.input_year {
    width: 20%;
    margin: .5rem auto;
}

.input_filename {
    width: 30%;
    margin: .5rem auto;
}

.buttonSearch {
    margin: .5rem 1rem 0 auto;
    line-height: 28px;
}

@media screen and (max-width: 768px) {
    .input_lessons {
        width: 100%;
    }
    
    .input_parallel {
        width: 100%;
    }
    
    .input_year {
        width: 100%;
    }
    
    .input_filename {
        width: 100%;
    }

    .input_search {
        width: 100%;
        margin: .5rem 0 0 0;
    }

    .wrapper_selects {
        flex-direction: column;
        align-items: center;
        margin: 2rem 0;
    }

    .wrapperTop {
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .input_lessons {
        width: 100%;
    }
    
    .input_parallel {
        width: 100%;
    }
    
    .input_year {
        width: 100%;
    }
    
    .input_filename {
        width: 100%;
    }
}
.pagination {
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
}

.item {
    user-select: none;
}
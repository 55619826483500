@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  /* border: 1px solid red; */
}


body::-webkit-scrollbar, body div::-webkit-scrollbar {
  background-color: rgba(238, 238, 238, 0.562);
  width: .8rem;
  height: .8rem;
}

body::-webkit-scrollbar-thumb, body div::-webkit-scrollbar-thumb {
  background-color: #376fb9;
  border-radius: 1rem;
}
.backIcon {
  position: absolute;
  top: -10px;
  left: -5vw;
  cursor: pointer;
}

.backIcon > svg {
  font-size: 30px;
  color: #376fb9;
}
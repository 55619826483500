.wrapper {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: relative;
}

.wrapper > header {
    background-color: #376fb9;
    user-select: none;
    padding: 0.5rem auto;
    color: white;
    overflow: hidden;
}

.headContent {
    width: 100%;
    max-width: 1300px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: 1rem;
}

.headContent > div {
    width: calc(100% - 390px);
}

.ministry {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.library {
    font-size: 21px;
}

.subHeader {
    margin-top: .5rem;
    font-size: 16px;
    line-height: 1.8;
}

.headContent img {
    position: absolute;
    width: 390px;
    height: 170px;
    bottom: -57px;
    right: 0;
}


.exitBlock {
    span {
        font-size: .9rem;
        display: inline-block;
        transition:  .5s;
        border-bottom: transparent;


        &:first-child {}

        &:last-child {
            cursor: pointer;
            margin-left: 1rem;
            line-height: 1.1;
            font-size: 18px;

            &:hover {
                border-bottom: 1px solid rgb(233, 233, 233);
            }
        }
    }
}

.wrapper > main {
    min-height: calc(100vh - (170px + 35px));
    height: 100%;
    padding-bottom: 60px;
}

.wrapper > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: #c7c7c7;
    box-shadow: 0 1px 7px 0 rgba(0,0,0,.75);
    z-index: 10;
    background-color: #ffffff;
}

.version {
    position: absolute;
    bottom: calc(50% - .5rem);
    left: .5rem;
}

@media screen and (max-width: 768px) {
    .headContent > div {
        width: 100%;
    }

    .headContent img {
        position: absolute;
        width: 300px;
        height: auto;
        bottom: -57px;
        right: -100px;
    }
}
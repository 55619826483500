.wrapper > p:first-child {
  color: #376fb9;  
}

.noFiles {
  font-weight: 600;
  color: #7d7d7d;
  letter-spacing: -1px;
}

.wrapper thead tr {
    background-color: #4682b45e;
    color: #616060;
}

.wrapper th:last-child {
  width: 36px;
}


.wrapper tbody tr:hover {
  cursor: pointer;
  background-color: #afcff66c;
}

.wrapper svg:hover {
  transform: scale(1.1);
  transition: .2s;
}

.loadedFile:hover {
  text-decoration: underline;
}

.buttonsBlock {
  display: flex;
}

.buttonsBlock > * + * {
  margin-left: 1rem;
}

.topicModal {
  width: 100%;
  min-width: 300px;
  max-width: 1000px !important;
  padding-top: 30px;
}

.topicFiles {
  width: 100%;
  min-height: 300px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  padding: .5rem;
  display: flex;
  flex-wrap: wrap;
}

.center {
  justify-content: center;
  align-items: center;
}

.fileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  position: relative;
  width: fit-content;
}


.fileLabel > svg {
  font-size: 60px;
  color: rgb(197, 195, 195);
  transition: all 0.2s ease-in;
}

.fileLabel:hover > svg {
  color: rgb(163, 163, 163);
}

.fileLabel > span {
  max-width: 80px;
  word-break: break-all;
  transition: all 0.2s ease-in;
  user-select: none;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in;
}

.fileLabel:hover > span {
  color: brown;
}
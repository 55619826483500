.wrapper {
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.title {
    font-size: 1.5rem;
    margin: 2rem .5rem;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        width: 90%;
        margin: 0 auto;
        padding: 0 0;
    }
}
.table {
    /* display: flex; */
    margin: 1rem 11px 0;
    overflow: auto;
    min-width: 100%;
}

.table thead {
    position: sticky;
    top: 0;
    background-color: #376fb9;
    color: #FFF;
}

.table table, .table table th, .table table td {
    border: 1px solid rgb(196, 196, 196);
    border-collapse: collapse;
}

.table tbody tr:hover {
    transition: .3s background-color;
    background-color: #d9e1f2;
}

.table tbody tr img:hover {
    cursor: pointer;
}

.table th {
    cursor: pointer;
}

.table table {
    table-layout: fixed;
    width: 100%;
    min-width: 1200px;
}

.image {
    width: 25px;
    margin: 0 .5rem 0 0;
    border: 1px solid grey;
}

.icon {
    width: 25px;
    font-size: 25px;
    color: red;
    margin: 0 .5rem 0 0;
}

.titleTd {
    overflow-wrap: anywhere;
}
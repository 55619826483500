

.wrapper {
  margin-bottom: 1rem;

  & table {
    width: 80%;
  }

  & thead tr {
      background-color: #4682b45e;
      color: #616060;
  }
}
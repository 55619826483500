.container {
    width: 70%;
    margin: 2rem auto 0;

    position: relative;
}

.requestWrapper {

}

.requestWrapper > button:first-child {
    margin: 2rem 0;
}

.stageRow {

    position: relative;

    > h3 {
        color: #376fb9;
        text-align: center;
        text-decoration: underline;
    }

    > svg {
        position: absolute;
        top: .5rem;
        width: fit-content;
        left: calc(50% + 70px);
        font-size: 24px;
        cursor: pointer;
    }

    > ul {
        list-style: none;
    }
    
    > ul > li {
        margin-top: 1rem;
    }
    
    > ul b {
        color: #376fb9;
        font-size: 1.2rem;
    }
    
    > ul table {
        width: 80%;
    }
    
    > ul thead tr {
        background-color: #4682b45e;
        color: #616060;
    }
    
    > b {
        text-align: center;
        line-height: normal;
        margin: -.5rem 0 1.5rem;
        font-size: .9rem;
        color: #376fb9;
    }
} 


.resumeTable {
    
    tbody {
        td {

            word-break: break-word;

            &:nth-child(2) > div {
                display: flex;
                flex-wrap: wrap;
                width: 222px;
            }


            &:nth-child(3),
            &:nth-child(4) {
                text-align: center;
            }
        }
    }

    th {
        text-align: center;
    }

    td {
        svg {
            color: #376fb9;
            font-size: 1.2rem;

            &:hover {
                transform: scale(1.3);
                transition: .2s;
            }
        }
    }
}

.teacherFilesBlock {
    display: flex;
    flex-wrap: wrap;
}

.fileItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fileItem > p {
    margin-bottom: 0;
    font-size: 11px;
    font-style: italic;
}

.fileItem > p:nth-child(2) {
    margin-top: -10px;
}

.requestWrapper tbody tr:hover {
    background-color: #afcff66c;
    cursor: pointer;
    transition: .5s;
}

.titleTd {
    text-align: center;
}

.filesTd > div {
    display: flex;
    flex-wrap: wrap;
    width: 220px;
    overflow: hidden;
    justify-content: center;
}

.formLabels {
    color: #376fb9;
}

.responsibleBlock > p {
    color: #376fb9;
    font-size: 1.2rem;
    font-weight: 500;
}

.buttonsBlock {
    margin-top: 2rem;
    display: flex;
}

.buttonsBlock button + button {
    margin-left: 2rem;
}

.criterionWithChild {
    background-color: #fff2dc6c;
    cursor: initial !important;
}

.noDataRow {
    text-align: center;
    font-style: italic;
    color: grey;
}

/* --------------------------------------------------EditModal-------------------------------------- */

.criterionModal > h4 {
    margin-bottom: 0;
}

.criterionDescription {
    font-size: .7rem;
    font-style: italic;
    transition: 2s linear;
    overflow: auto;
    height: auto;
}

.quill > div:first-child {
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
}

.quill > div:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    height: 200px;
}

.quillDisabled > div:last-child {
    background-color: #e9ecef;
}

.error > div {
    border: 1px solid red !important;
}

.criterionButtons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.criterionButtons button + button {
    margin-left: 2rem;
}

.pointsRow {
    justify-content: space-evenly;
}

.validPoints {
    text-align: center;
    width: 100%;
    font-size: .9rem;
    font-style: italic;
}

.bottomPart {
    overflow: hidden;
    height: auto;
}

.minimizeBlock {
    height: 0;
}

/* --------------------------------------------------rightButtonsBlock-------------------------------------- */

.rightButtonsBlock {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 3;

    button {
        width: fit-content;

    }
    
    > * + * {
        margin-top: .3rem;
    }
}



/* --------------------------------------------------ComissionBlock-------------------------------------- */


.comissionBlock {
    
}

.comissionBlock table {
    width: 80%;
}

.comissionBlock thead tr {
    background-color: #4682b45e;
    color: #616060;
}

.comissionBlock td:nth-child(3), th:nth-child(3) {
    text-align: center;
}

.approveBlock {
    display: flex;
}

.approveBlock > * + * {
    margin-left: 2rem;
}




/* --------------------------------------------------minimizeBlock-------------------------------------- */


.minimizeButtonBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    > button {
        background-color: transparent;
        color: #376fb9;
        border: none;
        outline: none;
        display: flex;
        align-items: center;

        > svg {
            margin-right: .3rem;
            transition: .2s;
        }
    }

}

.reverse svg {
    transform: rotate(180deg);
}



/* --------------------------------------------------awardsBlock-------------------------------------- */


.addAwardRow {
    border-bottom: none;
}

.addAwardRow:hover {
    background-color: transparent !important;
}

.addAwardRow > td {
    border-bottom: none;
}

.awardRow svg:hover, .addAwardRow svg:hover {
    transform: scale(1.2);
    transition: .2s;
}



/* --------------------------------------------------RequestEdit-------------------------------------- */

.infoModal {

    h2 {
        text-align: center;
        color: #376fb9;
    }

    .yearsInput  
    > input {
        width: 60px;
    }

    .filesBlock {
        display: flex;
        margin-bottom: 2rem;
    }

    .filesBlock > div {
        flex-basis: 50%;
    }

    .requestFiles > div, .teacherInfoFiles > div {
        display: flex;
        flex-wrap: wrap;
    }

    .fileItem + .fileItem {
        margin-left: 1rem;
    }

    .fileItem > span {
        color: #376fb9;
        display: flex;
        align-items: end;
        height: 50px;
        margin-bottom: .5rem;
    }

    .buttonsBlock {
        display: flex;
        gap: 1rem;
        justify-content: center;
    }
}
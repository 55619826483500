.container {
    width: 70%;
    margin: 0 auto;
}

.requestForm {
    margin-top: 2rem;
}

.requestForm h3, .tableContainer h3 {
    text-align: center;
    color: #336fb9;
}

.formLabels {
    color: #376fb9;
}

.tableContainer {
    margin-top: 3rem;
}

.tableBlock {
    position: relative;
    /* max-height: 70vh; */
    overflow: auto;
    width: 90vw;
    margin-left: -10vw;
}

.tableBlock thead {
    position: sticky;
    top: 0;
    background-color: white;
}

.tableBlock tbody tr:hover {
    background-color: #afcff66c;
    cursor: pointer;
    transition: .5s;
}

.noData {
    margin: 50px auto 0;
    text-align: center;
    font-size: 20px;
    letter-spacing: -1px;
}

.butttonsBlock {
    display: flex;
    margin-top: 1rem;
}

.butttonsBlock > * + * {
    margin-left: 1rem;
}

.butttonsBlock > button:first-child {
    width: 220px;
}




/* -----------------------ReasonModal----------------------------------- */


.reasonQuill > div:first-child {
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
}

.reasonQuill > div:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    height: 200px;
}

.quillDisabled > div:last-child {
    background-color: #e9ecef;
}



.reasonButtonsBlock {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.reasonButtonsBlock > * + * {
    margin-left: 1rem;
}

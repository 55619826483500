.fileInputWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}


.fileInputWrapper > div:first-child {
    border-radius: .5rem;
    border: 1px dashed rgb(235, 232, 232);
    border-bottom: none;
    flex-basis: 100px;
}

.fileInputWrapper > div:last-child {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    flex-basis: calc(100% - 100px);
}

.filesList {
    width: 100%;
    display: flex;
    overflow-x: auto;
}

.inputField {
    border: 1px dashed rgb(197, 196, 196);
    color: rgb(197, 196, 196);
    text-align: center;
    transition: .5s;
    user-select: none;
}

.inputField:hover {
    border-style: solid;
    border-color: #698fc5;
    color: #698fc5;
}

.inputField > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputField p {
    margin-top: 1rem;
}

.error {
    color: red;
    border-color: red;
}

.disabled {
    opacity: 0.8;
    pointer-events: none;
}